// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-chords-js": () => import("./../../../src/pages/chords.js" /* webpackChunkName: "component---src-pages-chords-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-the-advancing-guitarist-js": () => import("./../../../src/pages/the-advancing-guitarist.js" /* webpackChunkName: "component---src-pages-the-advancing-guitarist-js" */),
  "component---src-pages-western-modes-js": () => import("./../../../src/pages/western-modes.js" /* webpackChunkName: "component---src-pages-western-modes-js" */)
}

